import React from 'react'

function PageNotFound() {
  return (
      <div className='text-center' style={{marginTop:'200px', marginBottom:'400px'}}>
       <strong><h1>Page not Found</h1></strong> 
    </div>
  )
}

export default PageNotFound